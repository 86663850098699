<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {getDealApi} from '@/api/deal'

/**
 * User list component
 */
export default {
  page: {
    title: "Pending Deal",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "Pending Deal",
      items: [
        {
          text: "Deal",
          href: "/",
        },
        {
          text: "Pending",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      searchKey : '',
      fields: [
        { key : "U", sortable : false},
        {key : "LateDep", label:'L', sortable : false},
        {key : "SubjectRemoveFlag", label:'S', sortable : false},
        {
          key: "DealId",
          sortable: false,
        },
        {
          key: "MLS",
          sortable: false,
        },
        {
          key: "Agent",
          sortable: true,
        },
        {
          key: "Address",
          sortable: true,
        },
       
        {
          key: "SubjectRemove",
          sortable: true,
        },
        {
          key: "CompletionDate",
          sortable: true,
        },
        {
          key: "Price",
          sortable: true,
        },
        
        {
          key: "Status",
          sortable: false,
        },
      
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  watch: {
    sortBy : function(){this.queryList()},
    sortDesc : function(){this.queryList()}
  },

  created() {
    this.queryList(this.currentPage)
  },
  mounted() {
  
  },
  methods: {

    queryList() {

      let status_list = ['SUBMITTED','PROCESSING','REJECTED','APPROVED','PENDING_CLOSE']

      let data = {
        status: status_list,
         page : {
          page : this.currentPage,
           page_size: this.perPage
        },
        enable_staff : 1,
      }


      if (this.sortBy) {
        if (this.sortBy == 'CompletionDate') {
          data.sort_by = 'completion_date'
        } else  if (this.sortBy == 'SubjectRemove') {
          data.sort_by = 'subject_remove_date'
        } else if (this.sortBy == 'Price') {
          data.sort_by = 'selling_price'
        }
        data.sort_desc = this.sortDesc?' DESC ' : ' ASC '
      }
      if (this.searchKey) {
        data.search_key = this.searchKey
        data.page.page = 1
        this.currentPage = 1
        delete data.status
      }

     
      getDealApi().list(data).then((res)=>{
        this.listingData = []
        res.data.map((o) =>{
        
          this.listingData.push({
            id: o.id,
            deal_id : o.order_deal_id,
            unit : o.unit,
            address :o.address,
            price : o.selling_price,
            status : o.order_state,
            city : o.city_name,
            completion_date  : o.completion_date,
            deal_type : o.deal_type,
            subject_remove_date : o.subject_remove_date,
            mls : o.mls,
            native_agents : o.native_agents,
            updated_flag : o.updated_flag,
            staff : o.staff,
            late_deposit_flag : o.late_deposit_flag,
            subject_remove_flag : o.subject_remove_flag
          })
          this.totalRows = res.page.total;
         
        })  

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.contact.file = URL.createObjectURL(file);
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      console.log("====>",filteredItems)
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onEnterSearch() {
      this.queryList();
    },


  },
  validations: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="mb-3">
                  <h5 class="card-title">
                    Pending Deal List
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-4">
              </div>
              <div class="col-md-4">
                <div
                  class="align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >
                  <div class="search-box ">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control bg-light border-light rounded"
                        v-model="searchKey"
                        placeholder="Search by Deal Id or address or MLS..."
                        @keyup.enter="onEnterSearch"
                      />
                      <i class="uil uil-search search-icon"></i>
                    </div>
                  </div>

                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                class="datatables"
                  :items="listingData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  sort-icon-left
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"

                >

                <template  #cell(U)="data">
                  <feather v-if="data.item.updated_flag == 1" type="arrow-right-circle"></feather>
                </template>
                
                <template  #cell(LateDep)="data">
                  <feather v-if="data.item.late_deposit_flag == 1" type="clock"></feather>
                </template>

                <template  #cell(SubjectRemoveFlag)="data">
                  <feather v-if="data.item.subject_remove_flag == 1" type="check"></feather>
                </template>
                
                

                  <template  #cell(DealId)="data">
                    <router-link
                      :to="{ name : 'deal-overview', query: {deal_id : `${data.item.deal_id}` }}"
                      class="text-body fw-medium"
                      >{{ data.item.deal_id}}</router-link
                    >
                  </template>
                  <template #cell(MLS)="data"> 
                   {{ data.item.mls }}
                  </template>
                  <template #cell(Agent)="data"> 
                    <span v-for="(a, idx) in data.item.native_agents" :key="'agent_'+a.id+'_'+idx">
                      <router-link
                      :to="{name : 'brokerage-agent-view', params:{agent : a, agent_id : a.native_id+''}}"
                      class="text-body fw-medium"
                      >{{ `${a.first_name} ${a.last_name}`}}
                      </router-link><br />
                    </span>
                  </template>
                  <template #cell(Address)="data"> 
                   {{ data.item.address }}
                  </template>

                  <template #cell(Price)="data">
                    ${{ Number(data.item.price).toLocaleString() }}
                  </template>

                  <template #cell(SubjectRemove)="data">
                    {{ data.item.subject_remove_date?data.item.subject_remove_date  : 'N/A'}}
                  </template>
                  <template #cell(CompletionDate)="data">
                    {{ data.item.completion_date }}
                  </template>
                  
                  <template #cell(Operator)="data">
                    {{ data.item.staff?data.item.staff.full_name : ''  }}
                  </template>
                  <template #cell(Status)="data">
                    {{ data.item.status }}
                  </template>
                  <template #cell(Type)="data">
                    {{ data.item.deal_type }}
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
